<template>
	<v-card class="mx-auto" :loading="loadingParent" rounded="xl">
		<v-card-title>
			<span>{{ $t('profile.brandTalentPool') }}</span>
			<v-spacer />
			<v-dialog v-model="filtersEditMode" max-width="1600px">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4">
							mdi-filter
						</v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
					</v-card-title>
					<v-card-text>
						<v-text-field v-model="filters.searchName" :label="$t('auth.name')" :placeholder="$t('search.search')" outlined />
						<v-row>
							<v-col cols="12" md="6" sm="12">
								<v-autocomplete
									v-model="filters.skills"
									:items="availableSkills"
									:loading="loadingSkills"
									:search-input.sync="filters.skillSearch"
									:filter="customFilter"
									hide-details
									hide-selected
									item-text="name"
									item-value="id"
									:label="$t('cv.skills')"
									:placeholder="`${$t('search.search')}...`"
									return-object
									multiple
									chips
									outlined
								>
									<template #selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" close @click:close="removeSkill(data.item)">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-autocomplete
									v-model="filters.languages"
									:items="availableLanguages"
									:loading="loadingLanguages"
									:search-input.sync="filters.languageSearch"
									:filter="customFilter"
									hide-details
									hide-selected
									item-text="name"
									item-value="id"
									:label="$t('cv.languages')"
									:placeholder="`${$t('search.search')}...`"
									return-object
									multiple
									chips
									outlined
								>
									<template #selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" close @click:close="removeLanguage(data.item)">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-autocomplete
									v-model="filters.sectors"
									:items="availableSectors"
									:loading="loadingSectors"
									:search-input.sync="filters.sectorSearch"
									:filter="customFilter"
									hide-details
									hide-selected
									item-text="name"
									item-value="id"
									:label="$t('companies.sectors')"
									:placeholder="`${$t('search.search')}...`"
									return-object
									multiple
									chips
									outlined
								>
									<template #selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" close @click:close="removeSector(data.item)">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-autocomplete
									v-model="filters.degrees"
									:items="availableDegrees"
									:loading="loadingDegrees"
									:search-input.sync="filters.degreeSearch"
									:filter="customFilter"
									hide-details
									hide-selected
									item-text="listName"
									item-value="id"
									:label="$t('studies.studies')"
									:placeholder="`${$t('search.search')}...`"
									return-object
									multiple
									chips
									outlined
								>
									<template #selection="data">
										<v-chip
											v-bind="data.attrs"
											:input-value="data.selected"
											close
											@click.stop="toogleEnded(data.item)"
											@click:close="removeDegree(data.item)"
											:color="data.item.ended ? 'red' : ''"
										>
											{{ data.item.listName }} <span v-if="data.item.ended">({{ $t('studies.ended') }})</span>
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<p>{{ $t('settings.documents') }}</p>
								<v-chip-group multiple active-class="primary--text">
									<v-chip v-model="filters.cv" key="documents.cv">
										{{ $t('documents.cv') }}
									</v-chip>
									<v-chip v-model="filters.records" key="documents.records">
										{{ $t('documents.records') }}
									</v-chip>
									<v-chip v-model="filters.motivation" key="documents.motivation">
										{{ $t('documents.motivationLetter') }}
									</v-chip>
								</v-chip-group>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="secondary" text @click="filtersSwitchEditMode()">
							{{ $t('search.close') }}
						</v-btn>
						<v-btn color="primary" text @click="updateTable()">
							{{ $t('search.filter') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-title>
		<v-card-text>
			<div v-if="filtersActive" key="activeFiltersDegrees">
				<p class="text-subtitle-1">{{ $t('offers.activeFilters') }}</p>
				<v-chip v-for="skill in filters.skills" :key="`skillchip${skill.id}`" class="mx-1">
					{{ skill.name }}
				</v-chip>
				<v-chip v-for="language in filters.languages" :key="`languagechip${language.id}`" class="mx-1">
					{{ language.name }}
				</v-chip>
				<v-chip v-for="sector in filters.sectors" :key="`sectorchip${sector.id}`" class="mx-1">
					{{ sector.name }}
				</v-chip>
				<v-chip :color="degree.ended ? 'red' : ''" v-for="degree in filters.degrees" :key="`degreechip${degree.id}`" class="mx-1">
					{{ degree.listName }} <span v-if="degree.ended">({{ $t('studies.ended') }})</span>
				</v-chip>
			</div>

			<v-fade-transition group hide-on-leave>
				<template v-if="loadingPool">
					<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5" max-width="1200" />
				</template>

				<template v-else-if="talentPool.length">
					<v-data-table
						:headers="headers"
						:items="talentPool"
						:options.sync="options"
						:items-per-page="options.itemsPerPage"
						:footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
						:server-items-length="totalData"
						:loading="loadingPool"
						:locale="$i18n.locale"
						@update:items-per-page="updateTable()"
						@update:page="updateTable()"
						disable-sort
						class="elevation-1"
						key="dataTableTalentPool"
					>
						<template #item="{item, isMobile}">
							<template v-if="isMobile">
								<OfferTalentPoolUserItemMobile :loading-parent="loadingOffer" :item="item" />
							</template>
							<template v-else>
								<OfferTalentPoolUserItemDesktop :loading-parent="loadingOffer" :item="item" />
							</template>
						</template>
					</v-data-table>
				</template>

				<template v-else>
					<div key="noItemsData" class="mx-auto">
						<div class="mt-2 mb-5 text-center">
							<v-icon size="128" role="img">
								mdi-package-variant
							</v-icon>
							<br />
							<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
						</div>
						<h4 class="m-4 text-center">
							{{ $t('offers.myOffersHelp') }}
						</h4>
					</div>
				</template>
			</v-fade-transition>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'OfferTalentPool',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loadingIncluding: false,
			loadingPool: false,
			loadingDocument: false,
			loadingSkills: false,
			loadingSectors: false,
			loadingLanguages: false,
			loadingDegrees: false,
			filtersEditMode: false,
			filters: {
				searchName: '',
				skillSearch: '',
				skills: [],
				languageSearch: '',
				languages: [],
				sectorSearch: '',
				sectors: [],
				degreeSearch: '',
				degrees: [],
				cv: false,
				records: false,
				motivation: false
			},
			pages: 1,
			itemsPerPageOptions: [10, 15, 25, 50],
			totalData: 0,
			options: {
				page: 1,
				itemsPerPage: 15
			},
			headers: [
				{ text: this.$t('auth.name'), value: 'user', align: 'center' },
				{ text: this.$t('settings.documents'), value: 'documents', align: 'center' },
				{ text: this.$t('settings.action'), value: 'action', align: 'center' }
			]
		}
	},
	components: {
		OfferTalentPoolUserItemDesktop: () => import('@/components/offers/OfferTalentPoolUserItemDesktop.vue'),
		OfferTalentPoolUserItemMobile: () => import('@/components/offers/OfferTalentPoolUserItemMobile.vue')
	},
	watch: {
		'filters.skillSearch'(val) {
			if (val) this.queryAvailableSkills(val)
		},
		'filters.languageSearch'(val) {
			if (val) this.queryAvailableLanguages(val)
		},
		'filters.sectorSearch'(val) {
			if (val) this.queryAvailableSectprs(val)
		},
		'filters.degreeSearch'(val) {
			if (val) this.queryAvailableDegrees(val)
		}
	},
	computed: {
		filtersActive() {
			return this.filters.skills.length || this.filters.languages.length || this.filters.sectors.length || this.filters.degrees.length
		},
		skillsToQuery() {
			return this.filters.skills.map((e) => e.id).join(',')
		},
		languagesToQuery() {
			return this.filters.languages.map((e) => e.id).join(',')
		},
		sectorsToQuery() {
			return this.filters.sectors.map((e) => e.id).join(',')
		},
		degreesToQuery() {
			return this.filters.degrees.map((e) => [e.id, e.ended ? 'y' : ''])
		},
		...mapGetters({
			offer: 'offers/offer',
			talentPool: 'users/talentPool',
			availableSkills: 'cv/availableSkills',
			availableLanguages: 'cv/availableLanguages',
			availableSectors: 'cv/availableSectors',
			availableDegrees: 'data/availableDegrees'
		})
	},
	created() {
		this.fetchAvailableOfferTypes()
		this.queryAvailableSkills('')
		this.queryAvailableLanguages('')
		this.queryAvailableSectors('')
		this.queryAvailableDegrees('')
		this.options.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
		this.options.itemsPerPage = this.$route.query.itemsPerPage ? parseInt(this.$route.query.itemsPerPage) : 15
		this.filters.searchName = this.$route.query.search ? this.$route.query.search : ''
		this.updateTable()
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		queryAvailableSkills(search) {
			this.loadingSkills = true
			this.fetchAvailableSkills({ keywords: search }).then(() => {
				this.loadingSkills = false
			})
		},
		queryAvailableLanguages(search) {
			this.loadingLanguages = true
			this.fetchAvailableLanguages({ keywords: search }).then(() => {
				this.loadingLanguages = false
			})
		},
		queryAvailableSectors(search) {
			this.loadingSectors = true
			this.fetchAvailableSectors({ keywords: search }).then(() => {
				this.loadingSectors = false
			})
		},
		queryAvailableDegrees(search) {
			this.loadingDegrees = true
			this.fetchAvailableDegrees({ keywords: search }).then(() => {
				this.loadingDegrees = false
			})
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		goToPage(page) {
			this.options.page = page
			this.updateTable()
		},
		updateQuery() {
			if (
				this.$route.query.page != this.options.page ||
				this.$route.query.itemsPerPage != this.options.itemsPerPage ||
				this.$route.query.search != this.filters.searchName
			) {
				this.$router.replace({
					name: this.$route.name,
					query: {
						page: this.options.page,
						itemsPerPage: this.options.itemsPerPage,
						search: this.filters.searchName
					}
				})
			}
		},
		updateTable() {
			this.fetchData(
				this.options.page,
				this.options.itemsPerPage,
				this.filters.searchName,
				this.skillsToQuery,
				this.languagesToQuery,
				this.sectorsToQuery,
				this.degreesToQuery,
				this.filters.cv,
				this.filters.records,
				this.filters.motivation
			)
			this.updateQuery()
		},
		fetchData(page, itemsPerPage, search, skills, languages, sectors, degrees, cv, records, motivation) {
			this.loadingPool = true
			this.fetchTalentPool({ offerID: this.offer.id, page, itemsPerPage, search, skills, languages, sectors, degrees, cv, records, motivation })
				.then(({ pages, totalData }) => {
					this.pages = pages
					this.totalData = totalData
				})
				.then(() => {
					this.loadingPool = false
					this.filtersEditMode = false
				})
		},
		removeSkill(item) {
			const index = this.filters.skills.indexOf(item)
			if (index >= 0) this.filters.skills.splice(index, 1)
		},
		removeLanguage(item) {
			const index = this.filters.languages.indexOf(item)
			if (index >= 0) this.filters.languages.splice(index, 1)
		},
		removeSector(item) {
			const index = this.filters.sectors.indexOf(item)
			if (index >= 0) this.filters.sectors.splice(index, 1)
		},
		removeDegree(item) {
			const index = this.filters.degrees.indexOf(item)
			if (index >= 0) this.filters.degrees.splice(index, 1)
		},
		toogleEnded(item) {
			const index = this.filters.degrees.indexOf(item)
			if (index >= 0) this.filters.degrees[index].ended = !this.filters.degrees[index].ended
		},
		...mapActions('users', ['fetchTalentPool']),
		...mapActions('data', ['fetchAvailableOfferTypes', 'fetchAvailableDegrees']),
		...mapActions('cv', ['fetchAvailableSkills', 'fetchAvailableLanguages', 'fetchAvailableSectors'])
	}
}
</script>
